import {stopPropagationThen} from '@rasa.io/ui-components/components/button'
import classnames from 'classnames'
import {ClickOutside} from 'components/click-outside/component'
import {Pencil} from 'components/icons/pencil'
import React from 'react'
import {ChromePicker} from 'react-color'
import './styles.css'

export enum SelectColorIcons {
  PENCIL = 'pencil',
  ALPHABET = 'alphabet',
}
export interface SelectColorProps {
  className: string,
  color: string,
  selectColor: any,
  icon?: SelectColorIcons
}

export interface SelectColorState {
  editing: boolean,
}

export class SelectColor extends React.Component<SelectColorProps, SelectColorState> {
  constructor(props: SelectColorProps) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  public render() {
    return (
    <div className={classnames(this.props.className, 'select-color')}>
      {this.props.icon === SelectColorIcons.ALPHABET ?
      <span className="color-picker-label" style={{color: this.props.color}} onClick={
        stopPropagationThen(this.toggleEdit)
      }>A</span> :
      <div className="color-swatch" style={{backgroundColor: this.props.color}}>
        <Pencil onClick={stopPropagationThen(this.toggleEdit)} />
      </div>}
    {this.state.editing &&
      <ClickOutside handleClickOutside={this.toggleEdit}>
        <div className="rasa-color-picker">
          <ChromePicker
            disableAlpha
            color={this.props.color}
            onChangeComplete={(e) => this.props.selectColor(e.hex)}
          />
        </div>
      </ClickOutside>}
  </div>)
  }

  private toggleEdit = () => {
    this.setState({
      editing: !this.state.editing,
    })
  }
}
