import { SortDescriptor } from '@progress/kendo-data-query'
import {
  GridCellProps,
  GridColumn as Column,
  GridRowClickEvent,
} from '@progress/kendo-react-grid'
import { DateTimeCell } from 'components/gridcells/datetime'
import { HeaderComponent } from 'components/header/component'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { EditCellProps} from 'generic/editCell'
import * as GenericRedux from 'generic/genericRedux'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import * as GA from 'google-analytics'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import {UsageMeterComponent} from '../../shared/usage-meter-component'
import {UNLIMITED_VALUE} from '../../shared_server_client/constants'
import { getMaximumNumberOfNewsletters } from '../../shared_server_client/types/billing_plan'
import { SELECTED_COMMUNITY } from '../../store'
import { isLocal } from '../../utils'
import { FeatureUnavailableComponent, FeatureUnavailableComponentName } from '../feature-unavailable'
import {NEWSLETTER_ADDITIONAL_FEATURES} from '../upgrade/constants';
import './_styles.scss'
import * as GridCells from './gridCells'
import { EditNewsletterEditCell } from './kendocells/editNewsletterEditCell'
import { AddAccountModal, AddNewsletterModal, ExpirationModal } from './modals'
import { noMoreUpgradeAvailable } from 'generic/utility'

interface CommunitiesState {
  forceReload: boolean
  accountId?: number
  hasFeatureAccess: boolean,
  canAddNewsletters: boolean,
  currentNewsletterCount: number
  maxNewsletterCount: number
  canAddAccount: boolean,
  communityId?: number
  expirationDate?: Date
  isAdminUser: boolean,
  isLoading: boolean,
  isNoMoreUpgradeAvailable: boolean,
  loggedInUserId?: number
  search: string
  showGrid: boolean
  skip: number
  sort: SortDescriptor[]
  take: number
}

type CommunitiesListProps = GenericRedux.AllComponentPropsWithModal<any>

abstract class BaseCommunitiesListComponent extends Component<CommunitiesListProps, CommunitiesState>  {
  public static contextType = RasaContext
  private newslettersSearchTypingTimer
  private newslettersSearchTypingTimerInterval = 500 // .5 seconds

  constructor(props: any) {
    super(props)
    this.state = {
      forceReload: false,
      isAdminUser: false,
      isLoading: false,
      isNoMoreUpgradeAvailable: false,
      hasFeatureAccess: false,
      canAddNewsletters: false,
      currentNewsletterCount: 0,
      maxNewsletterCount: 0,
      canAddAccount: false,
      search: '',
      showGrid: false,
      skip: 0,
      sort: [],
      take: 25,
    }
  }

  public render() {
    return this.state.isLoading ? <Loading size="64" /> :
      (!this.state.hasFeatureAccess && !this.state.isAdminUser) ?
        <FeatureUnavailableComponent {...this.featureUnavailableProps()} /> :
        <div className="communities">
          <AddAccountModal
            data={this.props.modals}
            closeModal={this.props.closeModal}
            saveModal={this.props.saveModal}/>
          <AddNewsletterModal
            data={this.props.modals}
            closeModal={this.props.closeModal}
            saveModal={this.props.saveModal}/>
          <ExpirationModal
            data={this.props.modals}
            closeModal={this.props.closeModal}
            saveModal={this.props.saveModal}
            saveText="Update"
            expiration_date={this.state.expirationDate}
            onSave={this.onCommunityUpdate}
            title="Update newsletter"/>
          <div className="header-container">
            <HeaderComponent
              title={'Settings'}
              subTitle={'Your Newsletters'}
              description={['Select a newsletter']}
            />
            <div className="add-newsletter-wrapper">
              {this.renderUsageMeter()}
              {this.state.canAddAccount &&
                <Button className="add-button add-account"
                        onClick={() => this.props.openModal(AddAccountModal.key, null)}
                >
                + Add Account
                </Button>}
            </div>
          </div>
          <div className="search-bar-and-buttons-container">
            <div className="search-bar">
              <label>
                <SearchIcon />
                <input
                  type="text"
                  value={this.state.search}
                  placeholder="Search..."
                  onChange={(e) => {
                    const searchValue = e.target.value
                    clearTimeout(this.newslettersSearchTypingTimer)
                    this.setState({showGrid: false}, () => {
                      this.setSearch(searchValue)
                      this.newslettersSearchTypingTimer = setTimeout(this.onNewslettersSearchTypingDone,
                        this.newslettersSearchTypingTimerInterval)
                    })
                  }}
                />
              </label>
            </div>
          </div>
          {!this.state.showGrid
            ?
            <Loading size="64" />
            :
            <div className="clickable-item">
              {this.renderGrid()}
            </div>
          }
        </div>
  }

  protected renderUsageMeter = () => {
    const isUnlimited: boolean = this.state.maxNewsletterCount === UNLIMITED_VALUE
      || this.state.maxNewsletterCount === -1
    const currentNewslettersCount: number = this.state.currentNewsletterCount
    const maxNewslettersCount: number = this.state.maxNewsletterCount
    const isLimitReached: boolean = currentNewslettersCount >= maxNewslettersCount
    const disableActionButton: boolean = !this.state.canAddNewsletters
    const description: string = 'You\'ve reached your newsletters limit, please upgrade your plan to add a new newsletter.'
    const handleClickEvent = () => {
      this.props.openModal(AddNewsletterModal.key, null)
    }

    return <UsageMeterComponent
      actionButton={{
        disabled: disableActionButton,
        callback: handleClickEvent,
        label: '+ Add newsletter'
      }}
      entity='newsletters'
      isAdmin={this.state.isAdminUser}
      isUnlimited={isUnlimited}
      isLimitReached={isLimitReached}
      currentCount={currentNewslettersCount}
      maxLimit={maxNewslettersCount}
      source={GA.UpgradeSource.AddNewsletter}
      progressDescription={description}
      showContactToRasa={this.state.isNoMoreUpgradeAvailable}
      progressText='Newsletters Used' />
  }

  protected abstract renderGrid(): any

  protected MyLogoCell = (props: GridCellProps) => {
    return <GridCells.SelectCell {...props} onClick={this.selectCommunity} type={GridCells.SelectCellType.Logo}/>
  }

  protected MySelectCell = (props: GridCellProps) => {
    return <GridCells.SelectCell disabled={!this.canSelectCommunity(props.dataItem)}
      {...props} onClick={this.selectCommunity} type={GridCells.SelectCellType.Button}/>
  }

  protected MyExpirationCell = (props: GridCellProps) => {
    return <GridCells.ExpirationCell {...props} onClick={this.changeCommunity} />
  }

  protected selectCommunity = (selected: any) => {
    if (this.canSelectCommunity(selected)) {
        this.setState({isLoading: true})
        if (selected.dashboard_alternate_domain && !isLocal()) {
        // whitelist domain is setup for this newsletter. redirect to its website
        window.location.href = `https://${selected.dashboard_alternate_domain}?${SELECTED_COMMUNITY}=${selected.community_identifier}`
      } else {
        this.context.user.switchCommunity(selected.community_identifier).then(() => {
          this.setState({isLoading: false})
          return this.props.push('/')
        })
      }
    }
  }

  private canSelectCommunity = (selected: any) => {
    return (!selected.expiration_date || new Date(selected.expiration_date) > new Date())
  }

  private changeCommunity = (selected: any) => {
    this.setState({
      accountId: selected.community_account_id,
      communityId: selected.community_id,
      expirationDate: selected.community_expiration_date,
    }, () => {
      this.props.openModal(ExpirationModal.key, null);
    })
  }

  private onCommunityUpdate = (expirationDate) => {
    return this.context.entityMetadata
    .getEntityObject('account')
    .then((entity) => {
      entity.setRecordId(this.state.communityId, this.state.accountId)
      entity.data.expiration_date = expirationDate;
      return entity.save().then((e) => {
        this.setState({forceReload: true}, () => this.setState({forceReload: false}));
        this.props.closeModal(ExpirationModal.key, null);
      })
    })
  }

  private onNewslettersSearchTypingDone = () => {
    this.setState({showGrid: true})
  }

  private setSearch = (s: string) => {
   this.setState({
     search: s,
   })
  }

  private featureUnavailableProps = () => {
    return {
      loadComponent: FeatureUnavailableComponentName.Upgrade,
      source: GA.UpgradeSource.AddNewsletter,
      upgradeComponentProps: {
        source: GA.UpgradeSource.AddNewsletter,
        features: NEWSLETTER_ADDITIONAL_FEATURES,
        description: 'Unlock more templating and features when you upgrade your plan',
      },
    }
  }
}

class CommunitiesListComponent extends BaseCommunitiesListComponent {

  private communitiesData = []
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ])
    .then(([activeCommunity, role]) => {
      if (activeCommunity.billingInfo && activeCommunity.billingInfo.usageStats) {
        const maximumNumberOfNewsletter = getMaximumNumberOfNewsletters(activeCommunity.billingInfo.currentPlan)
        this.setState({
          isNoMoreUpgradeAvailable: noMoreUpgradeAvailable(activeCommunity.billingInfo.currentPlan),
          canAddNewsletters: !!activeCommunity.billingInfo.usageStats.canAddNewsletters,
          currentNewsletterCount: activeCommunity.billingInfo.usageStats.numNewsletters,
          maxNewsletterCount: maximumNumberOfNewsletter,
          hasFeatureAccess: maximumNumberOfNewsletter > 1
            || maximumNumberOfNewsletter === -1
            || maximumNumberOfNewsletter === UNLIMITED_VALUE,
        })
      }
      if (activeCommunity.role === Roles.super_admin) {
        this.setState({canAddAccount: true})
      }
      this.setState({
        isAdminUser: role === Roles.super_admin,
        showGrid: true,
      })
    })
  }

  public renderGrid() {
    return <RasaDataGrid
            // Rasa Properties for entity, datasetName etc
            entityName="identityCommunity"
            datasetName="userNewsletters"
            datasetParams={[
              { param: 'filterVal', value: this.state.search },
            ]}
            onItemChange={this.itemChanged}
            forceReload={this.state.forceReload}
            editDefaultState={false}
            onDataReceived={this.dataReceived}
            onRowClick={(e: GridRowClickEvent) =>
              this.selectCommunity(e.dataItem)
            }
            // events
            sortable={true}
            pageable={true}
            pageSize={25}
            data={[]}
          >
            <Column
              field="brand_header_image_url"
              title="Logo"
              cell={this.MyLogoCell}
              width={150}
            />
            <Column
              field="c.name"
              title={'Name & Date Created'}
              cell={GridCells.NameAndCreatedCell}
              width={200}
            />
            <Column field="last_actual" title="Last Sent #" width={150}/>
            <Column
              field="last_sent"
              title="Last Send"
              cell={DateTimeCell}
              width={200}
            />
            {this.state.isAdminUser &&
            <Column title="Identifier" cell={GridCells.MyCommunityIdentifier}    width={170}/>}
            <Column cell={this.MyExpirationCell} width={170}/>
            <Column cell={this.MySelectCell} width={170}/>
            <Column cell={this.CommunitiesEditCell} width={50}/>
          </RasaDataGrid>
  }
  public CommunitiesEditCell = (props: EditCellProps) => {
    const activeCommunities = this.communitiesData.filter((f) => f.is_active)
    const hideDots = this.state.isAdminUser ? false : (!props.dataItem.is_archived && activeCommunities.length === 1)
    return this.communitiesData.length > 1 || (this.state.isAdminUser && this.communitiesData.length > 0) ?
      <EditNewsletterEditCell {...props}
        hideDots={hideDots}
        canArchive={!props.dataItem.is_archived && (this.state.isAdminUser || activeCommunities.length > 1)}
        canCancelAccount={this.state.isAdminUser &&
          !props.dataItem.account_is_cancelled && (this.state.isAdminUser || activeCommunities.length > 1)}
        canSuspendAccount={this.state.isAdminUser &&
          !props.dataItem.community_account_suspended && (this.state.isAdminUser || activeCommunities.length > 1)}
        canActivateAccount ={this.state.isAdminUser && props.dataItem.community_account_suspended
          && activeCommunities.length > 1}
        activateAccountLabel = {'Remove Suspension'}
        canRestore={props.dataItem.is_archived}/> : null
  }

  protected dataReceived = (data) => {
    if (data.data) {
      this.communitiesData = data.data;
    }
  }
  protected itemChanged = (event) => {
    if (event.field === 'is_archived') {
      this.communitiesData.find((d) => d.id === event.dataItem.id).is_active = !event.value
      this.setState({
        currentNewsletterCount: this.state.currentNewsletterCount - 1
      })
    }
  }
}

export const CommunitiesList = GenericRedux.registerNewComponentWithModals<any>(
  CommunitiesListComponent,
  'communities_list',
  [AddNewsletterModal.key, ExpirationModal.key, AddAccountModal.key],
  {},
)
