import { GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid'
import { ActiveCell } from 'components/content-pool/kendocells/activeCell'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import { Component } from 'react'
import React from 'react'

interface Community {
  id: number,
  identifier: string,
  name: string,
}

interface SearchCommunitiesState {
  communities: any[],
  isLoading: boolean,
  search?: string,
  showGrid?: boolean,
}

interface SearchCommunitiesProps {
  selectedAccountId: number,
  onCommunitySelect: (selectedCommunities: Community[]) => void
}

export class SearchCommunities extends Component<SearchCommunitiesProps, SearchCommunitiesState> {
  private communitySearchTypingTimer
  private communitySearchTypingTimerInterval = 500 // .5 seconds
  private selectedCommunities: Community[] = []

  constructor(props: SearchCommunitiesProps) {
    super(props)
    this.state = {
      communities: [],
      isLoading: false,
      search: '',
      showGrid: true,
    }
    this.transformData = this.transformData.bind(this)
    this.onToggle = this.onToggle.bind(this)
  }

  public render() {
    return this.state.isLoading ? <Loading size="64"/> :
      (
        <div>
          {this.state.isLoading && this.state.showGrid &&
            <div>
              <div className="invite-user-search-accounts">
                <h4 className="label">Select your Newsletter</h4>
                <div className="search-bar-and-buttons-container">
                  <div className="search-bar">
                    <label>
                      <SearchIcon/>
                      <input
                        type="text"
                        value={this.state.search}
                        placeholder="Search newsletter..."
                        onChange={(e) => {
                          const searchValue = e.target.value
                          clearTimeout(this.communitySearchTypingTimer)
                          this.setState({showGrid: false}, () => {
                            this.setSearch(searchValue)
                            this.communitySearchTypingTimer = setTimeout(this.onSearchTypingDone,
                              this.communitySearchTypingTimerInterval)
                          })
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>}
          {this.state.showGrid && this.props.selectedAccountId > 0 &&
            <div className="invite-user-newsletter-grid">
              <RasaDataGrid
                // Rasa Properties for entity, datasetName etc
                entityName="communityArticle"
                datasetName="userNewsletters"
                datasetParams={[
                  {param: 'filter', value: this.state.search},
                  {param: 'accountId', value: this.props.selectedAccountId},
                ]}
                onDataReceived={this.dataReceived}
                gridTransform={this.transformData}
                editDefaultState={true}
                sortable={false}
                pageable={false}
                gridFilter={this.filterData}
                data={[]}>
                <Column cell={this.ActiveCell} width={170}/>
                <Column
                  field="community_name"
                  title={'Name'}
                />
              </RasaDataGrid>
            </div>}
        </div>
      )
  }

  private filterData = (a: any): boolean => {
    return (a.community_account_id === this.props.selectedAccountId)
  }

  private ActiveCell = (props: GridCellProps) => {
    return <ActiveCell {...props} onChange={null} onToggle={this.onToggle} fieldName="is_selected"/>
  }

  private dataReceived = (data) => {
    this.setState({
      communities: data.data,
      isLoading: false,
    })
  }

  private transformData = (a) => {
    const filteredCommunities = this.state.communities.filter(
      (x) => x.identity_community_id === a.identity_community_id)
    return filteredCommunities.length ? filteredCommunities[0] : a
  }

  private onToggle = (item) => {
    const existingItem = this.selectedCommunities.filter((x) => x.id === item.identity_community_id)[0]
    if (!existingItem) {
      this.selectedCommunities = [...this.selectedCommunities, {
        id: item.identity_community_id,
        identifier: item.id,
        name: item.community_name,
      }]
    } else {
      this.selectedCommunities = this.selectedCommunities.filter((x) => x.id !== item.identity_community_id)
    }
    this.props.onCommunitySelect(this.selectedCommunities)
    const updatedCommunities = this.state.communities.map((x) => {
      return {
        ...x,
        is_selected: x.identity_community_id === item.identity_community_id ?
          !x.is_selected : x.is_selected || false,
      }
    })
    this.setState({
      communities: updatedCommunities,
    })
  }

	private onSearchTypingDone = () => {
		this.setState({showGrid: true})
	}

  private setSearch = (s: string) => {
    this.setState({
      search: s,
    })
  }
}
