import { DropdownOption } from 'components/dropdown/component'

export const RasaBillingPlans: DropdownOption[] = [
  {
    description: 'Enterprise',
    key: 'rasa_enterprise',
    value: 'Enterprise',
  },
  {
    description: 'Pro',
    key: 'rasa_contract_pro',
    value: 'Pro',
  },
]