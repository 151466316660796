import {
  GridCell,
  GridCellProps,
} from '@progress/kendo-react-grid'
import { sizedUrl } from 'app/utils'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { Button } from 'reactstrap'
import { DATETIME_FORMAT } from 'shared/constants'

export class NameAndCreatedCell extends GridCell {
  public render() {
    return <td>
      <div className="newsletter-name">
        {this.props.dataItem.display_name || this.props.dataItem.community_name
        || this.props.dataItem.community_identifier}
      </div>
      <div className="newsletter-created-date">
        {this.props.dataItem.created_datetime ? format(
                  this.props.dataItem.created_datetime, DATETIME_FORMAT) :
                  this.props.dataItem.created ?
                  format(parseISO(this.props.dataItem.created), DATETIME_FORMAT) : ''}
      </div>
    </td>
  }
}
export class MyCommunityIdentifier extends GridCell {
  public render() {
    return <td>
      <div>
        {
         this.props.dataItem.community_identifier}
      </div>
    </td>
  }
}

interface SelectCellState {
  imageAvailable: boolean
}

export enum SelectCellType {
  Logo = 'Logo',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Button = 'Button',
}

interface SelectCellProps extends GridCellProps {
  onClick: any
  type: SelectCellType
  disabled?: boolean
}

export class SelectCell extends React.Component<SelectCellProps, SelectCellState> {
  constructor(p: SelectCellProps) {
    super(p)
    this.state = {
      imageAvailable: true,
    }
  }
  public render() {
    return this.props.type === SelectCellType.Logo
    ? <td className="community-selector">
      <a className={this.props.disabled ? 'disabled' : ''} href="#" onClick={this.logoClicked}>{ this.imageAvailable() ?
        <img src={sizedUrl(this.props.dataItem.brand_header_image_url, 85)} onError={this.noImage}/> :
        <span>{this.props.dataItem.community_name}</span>}
      </a>
    </td>
    : <td className="select-newsletter">
      <div>&nbsp;</div>
      <div>
      {this.props.dataItem.community_expiration_date ? <div>&nbsp;</div> : null }
        <Button disabled={this.props.disabled} onClick={this.logoClicked}>Select</Button>
      </div>
      </td>
  }

  private logoClicked = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.dataItem)
    }
  }

  private noImage = (e) => {
    if (this.imageAvailable()) {
      this.setState({
        imageAvailable: false,
      })
    }
  }

  private imageAvailable = (): boolean =>
    this.state.imageAvailable && this.props.dataItem.brand_header_image_url
}

interface ExpirationCellProps extends GridCellProps {
  onClick: any
}

export class ExpirationCell extends React.Component<ExpirationCellProps> {
  constructor(p: ExpirationCellProps) {
    super(p)
  }
  public render() {
    return <td className="change-newsletter">
        <div>{this.getExpirationStatus()}</div>
        { !this.props.dataItem.account_is_cancelled &&
          <div>
            <div>
              {this.props.dataItem.community_expiration_date ?
              format(parseISO(this.props.dataItem.community_expiration_date), DATETIME_FORMAT) : ''}
            </div>
            <Button onClick={this.buttonClicked}>Change</Button>
          </div>
        }
      </td>
  }
  private getExpirationStatus = () => {
    if (this.props.dataItem.account_options) {
      const accountOptions = JSON.parse(this.props.dataItem.account_options)
      if (accountOptions && accountOptions.status && accountOptions.status === 'Pending') {
        return 'Pending'
      }
    }
    if ( this.props.dataItem.account_is_cancelled) {
      return 'Cancelled'
    } else {
      const expirationDate = this.props.dataItem.community_expiration_date
      if (!expirationDate || new Date(expirationDate) > new Date()) {
        return 'Active'
      } else {
        return 'Expired'
      }
    }
  }
  private buttonClicked = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.dataItem)
    }
  }
}
