import { sizedUrl } from 'app/utils'
import React from 'react'

interface CommunityDropdownItemProps {
  community: any,
  onChange?: any,
}
interface CommunityDropdownItemState {
  validImage: boolean
}

export class CommunityDropdownItem extends React.Component<CommunityDropdownItemProps, CommunityDropdownItemState> {
  constructor(props: CommunityDropdownItemProps) {
    super(props)
    this.state = {
      validImage: true,
    }
  }
  public render() {
      return (
        <div className="dropdown-item" key={this.props.community.community_identifier} onClick={this.itemClick}>
          {this.props.community.brand_header_image_url && this.state.validImage ?
          <img className="dashboard-company-logo-img" onError={this.invalidImage}
                src={sizedUrl(this.props.community.brand_header_image_url, 45)}/> : this.getDisplayName()}
        </div>
      );
  }
  private itemClick = () => {
    if (this.props.onChange) {
      this.props.onChange(this.props.community)
    }
  }
  private invalidImage = () => {
    this.setState({
      validImage: false,
    })
  }
  private getDisplayName = () => {
    return this.props.community.display_name || this.props.community.community_name || 'Your Newsletter'
  }
}
