import { GridColumn as Column, GridRowClickEvent } from '@progress/kendo-react-grid'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import { isEmpty } from 'lodash'
import { Component } from 'react'
import React from 'react'

import { BillingPlanCode } from 'shared_server_client/types/billing_plan'

interface State {
	accounts: any[],
	isLoading: boolean,
	slectedAccountId: number,
	search?: string,
	showGrid?: boolean,
}

interface Props {
	onAccountSelect: any
}
export class SearchAccount extends Component<Props, State> {
	private accountsSearchTypingTimer
	private accountsSearchTypingTimerInterval = 500 // .5 seconds
	constructor(props: any) {
		super(props)
		this.state = {
			accounts: [],
			isLoading: false,
			slectedAccountId: 0,
			search: '',
			showGrid: false,
		}
	}

	public render() {
		return this.state.isLoading ? <Loading size="64" /> :
		(
			<div>
				<div>
					<h4 className="label">Select your account</h4>
					<div>
						<div className="search-bar-and-buttons-container">
							<div className="search-bar">
								<label>
									<SearchIcon />
									<input
										type="text"
										value={this.state.search}
										placeholder="Search an account..."
										onChange={(e) => {
											const searchValue = e.target.value
											clearTimeout(this.accountsSearchTypingTimer)
											this.setState({ showGrid: false }, () => {
												this.setSearch(searchValue)
												this.accountsSearchTypingTimer = setTimeout(this.onAccountsSearchTypingDone,
													this.accountsSearchTypingTimerInterval)
											})
										}}
									/>
								</label>
							</div>
						</div>
						{this.state.showGrid && !isEmpty(this.state.search) &&
							<div className="clickable-item clickable-item-rasa-grid">
								<RasaDataGrid
									// RASA Properties for entity, datasetName etc
									entityName=""
									datasetName="billingPlanAccounts"
									datasetParams={[
										{
											param: 'codeMonthly',
											value: this.accountPlanFilters().join(',')
									  },
										{ param: 'filter', value: this.state.search },
									]}
									editDefaultState={true}
									// events
									onDataReceived={this.dataReceived}
									onRowClick={(p: GridRowClickEvent) => this.setSelectedAccountId(p)}
									gridTransform={this.transformData}
									sortable={false}
									pageable={false}
									data={[]}>
									<Column field="company_name" title="Company Name" />
								</RasaDataGrid>
							</div>}
					</div>
				</div>
			</div>
		)
	}

	private accountPlanFilters = () => {
		return [
			BillingPlanCode.RASA_ENTERPRISE,
			BillingPlanCode.RASA_CONTACT_PRO,
			BillingPlanCode.ZOHO_PRO_2021,
			BillingPlanCode.ZOHO_PRO_2023,
			BillingPlanCode.ZOHO_PLUS_2021,
			BillingPlanCode.ZOHO_PLUS_2023,
			BillingPlanCode.ZOHO_PLUS_ANNUAL_2023,
			BillingPlanCode.ZOHO_PRO_ANNUAL_2023,
		]
	}

	protected dataReceived = (data) => {
		if (data.data) {
			this.setState({
				accounts: data.data,
				showGrid: true,
			});
		}
	}

	private onAccountsSearchTypingDone = () => {
		this.setState({ showGrid: true })
	}

	private transformData = (a) => {
		const filteredAccounts =
			this.state.accounts.filter((x) => x.id === a.id)
		return filteredAccounts.length ? filteredAccounts[0] : a
	}

	private setSearch = (s: string) => {
		this.setState({
			search: s,
		})
	}

	private setSelectedAccountId = (p: any) => {
		const updatedAccounts = this.state.accounts.map((x) => {
			return {
				...x,
				is_selected: x.id === p.dataItem.id ? true : false,
			}
		})
		this.setState({
			slectedAccountId: p.dataItem.id,
			accounts: updatedAccounts,
		}, () => {
			this.props.onAccountSelect(p.dataItem);
		})
	}
}
