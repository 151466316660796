import React, { useEffect, useRef } from 'react'

export const ClickOutside = (props: any) => {
  const ref = useRef(null)
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.handleClickOutside()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })
  return <div className="click-outside" ref={ref}>{props.children}</div>
}
