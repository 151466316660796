import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import { isNil } from 'lodash';
import { Component } from 'react'
import React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'

export const DEFAULT_SEGMENT_COUNT = 2

interface State {
  community_partner?: DropdownOption[],
  communityRecordId?: any,
  selected?: any,
  configData?: any,
  isLoading: boolean,
  config_data: any,
  communityId: string,
}

interface Props {
  code?: any,
  hideLabel?: boolean,
  label?: string,
  previewDate?: Date,
  segmentCode?: any,
  setConfigData?: any,
  setSegmentCode?: any,
  setSegmentData?: any,
}
export class LoadSegmentCodes extends Component<Props, State> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  private initialSegmentCode = {key: 'All', value: 'All', code: 'All'};
  constructor(props: any) {
    super(props)

    if (!isNil(props.segmentCode)) {
      this.initialSegmentCode.key = props.segmentCode
      this.initialSegmentCode.value = props.segmentCode
    }
    this.state = {
        community_partner: [],
        isLoading: true,
        config_data: [],
        communityId: null,
        selected: this.initialSegmentCode,
    }
    this.loadData = this.loadData.bind(this)
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.code !== this.props.code) {
      this.loadData()
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.setState({
        communityRecordId: activeCommunity.data.community_id,
        communityId: activeCommunity.communityId,
      }, () => this.loadData())
    })
  }

  public render() {
    return (
        <div>
          {(!this.state.isLoading) && this.state.community_partner &&
            this.state.community_partner.length > DEFAULT_SEGMENT_COUNT &&
            <div className="segment-code-wrapper">
              {!this.props.hideLabel && <span className="title">{this.props.label || 'Select Segment'}</span>}
              <div className="template-code">
                <DropdownComponent
                data={this.state.community_partner}
                selected={this.state.selected ? this.state.selected.key : null}
                onChange={this.changeCode}/>
              </div>
            </div>}
        </div>
    )
  }

  private changeCode = (e: any) => {
    this.setState({
      selected: e.selected,
    })
    if (this.props.setSegmentCode) {
      this.props.setSegmentCode(e.selected)
    }
    //fetch configuaration details
    return new Dataset().loadCommunityDataset('communityConfigurationsByPartnerCode',
    this.state.communityId,
    [{param: 'communityPartnerCodeVal', value: e.selected.value}])
      .then((cpConfigs) => {
        if (cpConfigs.length > 0) {
          this.setState({
            config_data: cpConfigs[0],
          })
          if (this.props.setConfigData) {
            this.props.setConfigData(cpConfigs[0])
          }
        }
      })
  }

  private normalizeCodes = (codes: any[]): DropdownOption[] => {
    const normalizedCodes = codes.map((s) => ({
      description: s.code, // We may use s.name in the future.
      key: s.code,
      value: s.code,
    }))
    return normalizedCodes
  }
  private loadData = () => {
    return new Dataset().loadCommunityDataset(
      'communityPartnerSegmentCode',
      this.state.communityId,
      [{param: 'includeDefaultOptions', value: 1}]
    )
    .then((dsResult) => {
      if (dsResult.length) {
        const segments = dsResult[0]
        if (this.props.setSegmentData) {
          this.props.setSegmentData(segments)
        }
        if (segments.length > DEFAULT_SEGMENT_COUNT) {
          if (this.props.setConfigData) {
            this.props.setConfigData(segments[DEFAULT_SEGMENT_COUNT])
          }
          this.setState({
            community_partner: this.normalizeCodes(segments),
          }, () => this.selectSegmentCode())
        }
      }
      this.setState({ isLoading: false })
    })
  }
  private selectSegmentCode = () => {
    const selectedOption: DropdownOption[] = this.state.community_partner
      .filter((item) =>  item.key === this.props.segmentCode)
    const selected = selectedOption[0] || this.initialSegmentCode
    this.setState({ selected })
    if (this.props.setSegmentCode) {
      this.props.setSegmentCode(selected)
    }
  }
}
