import classnames from 'classnames'
import React, { useState } from 'react'

import './_styles.scss'

export interface CollapseProps {
  children: any,
  disabled?: boolean,
  isOpen?: boolean,
  title: string,
}

export const Collapsable = (props: CollapseProps) => {
  const [collapsed, setCollapse] = useState(props.isOpen ? false : true)
  return <div className='collapsable'>
    <div className={classnames(`collapse-${collapsed}`, {'rasa-collapse-disabled': props.disabled})}>
      <div className="title-and-arrow-container">
        <div className="title">
          {props.title}
        </div>
        <div className="arrow-container clickable-item">
          <div onClick={() => setCollapse(!collapsed)}>
            <i className="fa fa-caret-right"/>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="content-children">
          {props.children}
        </div>
      </div>
    </div>
  </div>
}
